@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: light only;
}

canvas {
  touch-action: none;
}
